import "./egg.css";

const Egg = () => {
  return (
    <div className="eggCont">
      <h2>You Won!</h2>
      <h3>
        Screenshot this page and @ YOLO on twitter with the screenshot to claim
        your 10k $YOLO airdrop!
      </h3>
    </div>
  );
};

export default Egg;
