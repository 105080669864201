import { useState } from "react";
import "./overlayStyle.css";

const GameOverlay = ({ onStartGame }) => {
  const [handle, setHandle] = useState("");
  const [walletAddress, setWalletAddress] = useState("");

  const handleStart = (e) => {
    e.preventDefault();
    onStartGame({ handle, walletAddress });
  };

  return (
    <div className="overlay">
      <div className="overlay-content">
        <h1>Snake Game</h1>
        <p>Instructions:</p>
        <ul>
          <li>Use arrow keys or swipe to control the snake. </li>
          <li>Eat the food to grow the snake and increase your score. </li>
          <li>Don't hit the walls or the snake's own body. </li>
        </ul>
        <form onSubmit={handleStart}>
          <label>
            Twitter Handle:{" "}
            <input
              type="text"
              value={handle}
              onChange={(e) => setHandle(e.target.value)}
            />
          </label>
          <label>
            Wallet Address:{" "}
            <input
              type="text"
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
            />
          </label>
          <button type="submit">Start Game</button>
        </form>
      </div>
    </div>
  );
};

export default GameOverlay;
