import { Link } from "react-router-dom";
import "./navStyle.css";

const navBar = () => {
  return (
    <div className="navCont">
      <div className="links">
        <Link to="/">
          <a>Store</a>
        </Link>
        <Link to="/">
          <h1>YOLO</h1>
        </Link>
        <Link to="/snake">
          <a>Games</a>
        </Link>
      </div>
    </div>
  );
};

export default navBar;
