import { useEffect, useState } from "react";
import "./snakeStyle.css";
import GameOverlay from "./gameOverlay";

const SnakePage = () => {
  const [userInfo, setUserInfo] = useState({
    score: 0,
    twitHandle: "",
    walletAddress: "",
  });

  const [gameOver, setGameOver] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);

  const setScore = () => {
    setUserInfo((prevState) => ({
      ...prevState,
      score: prevState.score + 10,
    }));
  };

  var blockSize = 25;
  var rows = 20;
  var cols = 20;
  var board;
  var context;

  var snakeX = blockSize * 5;
  var snakeY = blockSize * 5;

  var velocityX = 0;
  var velocityY = 0;

  var snakeBody = [];

  var foodX;
  var foodY;

  useEffect(() => {
    if (gameStarted) {
      board = document.getElementById("board");
      board.height = rows * blockSize;
      board.width = cols * blockSize;
      context = board.getContext("2d");

      placeFood();
      document.addEventListener("keyup", changeDirection);
      const intervalId = setInterval(update, 1000 / 10);

      return () => {
        document.removeEventListener("keyup", changeDirection);
        clearInterval(intervalId);
      };
    }
  }, [gameStarted, gameOver]);

  useEffect(() => {
    let touchStartX = 0;
    let touchStartY = 0;
    let touchEndX = 0;
    let touchEndY = 0;

    const minSwipeDistance = 15;

    const handleTouchStart = (e) => {
      const gameCanvas = document.getElementById("board");
      if (e.target === gameCanvas) {
        e.preventDefault();
        const touch = e.touches[0];
        touchStartX = touch.clientX;
        touchStartY = touch.clientY;
      }
    };

    const handleTouchMove = (e) => {
      const gameCanvas = document.getElementById("board");
      if (e.target === gameCanvas) {
        e.preventDefault();
      }
    };

    const handleTouchEnd = (e) => {
      const gameCanvas = document.getElementById("board");
      if (e.target === gameCanvas) {
        e.preventDefault();
        touchEndX = e.changedTouches[0].clientX;
        touchEndY = e.changedTouches[0].clientY;

        handleSwipe();
      }
    };

    const handleSwipe = () => {
      const diffX = touchEndX - touchStartX;
      const diffY = touchEndY - touchStartY;

      if (
        Math.abs(diffX) > Math.abs(diffY) &&
        Math.abs(diffX) > minSwipeDistance
      ) {
        // Horizontal swipe
        if (diffX > 0 && velocityX !== -1) {
          // Swipe Right
          velocityX = 1;
          velocityY = 0;
        } else if (diffX < 0 && velocityX !== 1) {
          // Swipe Left
          velocityX = -1;
          velocityY = 0;
        }
      } else if (Math.abs(diffY) > minSwipeDistance) {
        // Vertical swipe
        if (diffY > 0 && velocityY !== -1) {
          // Swipe Down
          velocityX = 0;
          velocityY = 1;
        } else if (diffY < 0 && velocityY !== 1) {
          // Swipe Up
          velocityX = 0;
          velocityY = -1;
        }
      }
    };

    const attachTouchListeners = () => {
      const gameCanvas = document.getElementById("board");

      if (gameCanvas) {
        gameCanvas.addEventListener("touchstart", handleTouchStart, {
          passive: false,
        });
        gameCanvas.addEventListener("touchmove", handleTouchMove, {
          passive: false,
        });
        gameCanvas.addEventListener("touchend", handleTouchEnd, {
          passive: false,
        });
      }
    };

    const removeTouchListeners = () => {
      const gameCanvas = document.getElementById("board");

      if (gameCanvas) {
        gameCanvas.removeEventListener("touchstart", handleTouchStart);
        gameCanvas.removeEventListener("touchmove", handleTouchMove);
        gameCanvas.removeEventListener("touchend", handleTouchEnd);
      }
    };

    if (gameStarted) {
      attachTouchListeners();
    }

    return () => {
      removeTouchListeners();
    };
  }, [gameStarted, gameOver]);

  function update() {
    if (gameOver) {
      return;
    }
    context.fillStyle = "black";
    context.fillRect(0, 0, board.width, board.height);

    context.fillStyle = "white";
    context.fillRect(foodX, foodY, blockSize, blockSize);

    if (snakeX == foodX && snakeY == foodY) {
      snakeBody.push([foodX, foodY]);
      placeFood();
      setScore();
    }

    for (let i = snakeBody.length - 1; i > 0; i--) {
      snakeBody[i] = snakeBody[i - 1];
    }

    if (snakeBody.length) {
      snakeBody[0] = [snakeX, snakeY];
    }
    context.fillStyle = "yellow";
    snakeX += velocityX * blockSize;
    snakeY += velocityY * blockSize;
    context.fillRect(snakeX, snakeY, blockSize, blockSize);
    for (let i = 0; i < snakeBody.length; i++) {
      context.fillRect(snakeBody[i][0], snakeBody[i][1], blockSize, blockSize);
    }

    if (
      snakeX < 0 ||
      snakeX >= cols * blockSize ||
      snakeY < 0 ||
      snakeY >= rows * blockSize
    ) {
      endGame();
    }

    for (let i = 0; i < snakeBody.length; i++) {
      if (snakeX == snakeBody[i][0] && snakeY == snakeBody[i][1]) {
        setGameOver(true);
        alert("Game Over");
      }
    }
  }

  const startGame = ({ handle, walletAddress }) => {
    setUserInfo((prevState) => ({
      ...prevState,
      twitHandle: handle,
      walletAddress: walletAddress,
    }));
    setGameStarted(true);
  };

  function endGame() {
    setGameOver(true);
    alert("Game Over");
  }

  function changeDirection(e) {
    if ((e.code === "ArrowUp" || e.code === "KeyW") && velocityY !== 1) {
      velocityX = 0;
      velocityY = -1;
    } else if (
      (e.code === "ArrowDown" || e.code === "KeyS") &&
      velocityY !== -1
    ) {
      velocityX = 0;
      velocityY = 1;
    } else if (
      (e.code === "ArrowLeft" || e.code === "KeyA") &&
      velocityX !== 1
    ) {
      velocityX = -1;
      velocityY = 0;
    } else if (
      (e.code === "ArrowRight" || e.code === "KeyD") &&
      velocityX !== -1
    ) {
      velocityX = 1;
      velocityY = 0;
    }
  }

  function placeFood() {
    foodX = Math.floor(Math.random() * cols) * blockSize;
    foodY = Math.floor(Math.random() * rows) * blockSize;
  }

  const resetGame = () => {
    snakeX = blockSize * 5;
    snakeY = blockSize * 5;
    velocityX = 0;
    velocityY = 0;
    snakeBody = [];
    placeFood();
    setUserInfo((prevState) => ({
      ...prevState,
      score: 0,
    }));
    setGameOver(false);
  };

  return (
    <div>
      {!gameStarted && <GameOverlay onStartGame={startGame} />}
      <div>
        <h2 className="score">Score: {userInfo.score}</h2>
      </div>
      <canvas className="game" id="board" />
      <div>
        <button onClick={resetGame} disabled={!gameStarted}>
          Reset Game
        </button>
      </div>
    </div>
  );
};

export default SnakePage;
