import "./App.css";
import Card from "./Components/card/card";
import { Routes, Route } from "react-router-dom";
import Egg from "./Components/Egg/easterEgg";
import SnakePage from "./Components/snake/snakePage";
import Nav from "./Components/nav/nav.js";
import HamburgerMenu from "./Components/nav/HBM.js";
import useWindowSize from "./Components/Utility/useWindowSize.js";

function App() {
  const { width } = useWindowSize();
  return (
    <div className="App">
      {width <= 768 ? <HamburgerMenu /> : <Nav />}
      <Routes>
        <Route exact path="/" Component={Card} />
        {/* <Route path="/yolo" Component={Egg} /> */}
        <Route path="/snake" Component={SnakePage} />
      </Routes>
    </div>
  );
}

export default App;
