import React, { useState } from "react";
import "./HBMStyles.css";
import { Link } from "react-router-dom";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="hamburger-menu">
      <div className={`menu-icon ${isOpen ? "open" : ""}`} onClick={toggleMenu}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>

      <nav className={`menu-nav ${isOpen ? "open" : ""}`}>
        {/* <h2>YOLO</h2> */}
        <ul>
          <li>
            <Link to="/">
              <a>Home</a>
            </Link>
          </li>
          <li>
            <Link to="/">
              <a>Store</a>
            </Link>
          </li>
          <li>
            <Link to="/">
              <a>Lore</a>
            </Link>
          </li>
          <li>
            <Link to="/snake">
              <a>Games</a>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default HamburgerMenu;
