import "./card.css";
import merch from "./1-1-new.jpg";
import SocialButton from "./socialButton";
import dex from "./dex.png";
import tg from "./tg.png";
import xW from "./xW.png";

const Card = () => {
  return (
    <div className="card">
      <div className="card-content">
        <div className="card-headers">
          <h2 className="card-title">You Only Lose Once Investments</h2>
          <h2 className="card-title">
            <a href="https://dexscreener.com/solana/dzp2oszm2fiuyarvsak5of58hqevebwkjmvpqxcmnaeg">
              $YOLO
            </a>
          </h2>
        </div>
        <div className="context">
          <img className="card-image" src={merch} />
          <div className="social-links">
            <SocialButton
              platform="telegram"
              url="https://t.me/+5uqoLgEbg81iZTA5"
              img={tg}
            />
            <SocialButton
              platform="dexscreener"
              url="https://dexscreener.com/solana/dzp2oszm2fiuyarvsak5of58hqevebwkjmvpqxcmnaeg"
              img={dex}
            />
            <SocialButton
              platform="twitter"
              url="https://x.com/YouOnlyLoseOnce"
              img={xW}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
